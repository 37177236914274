import { rgba } from "polished";
import styled, { css } from "styled-components";
const background = "/images/flow/documents-opacity.svg";

export const Title = styled.h1`
  ${({ theme }) => css`
    color: #9eb9c3;
    text-align: center;
    font-size: 3rem;
    font-family: ${theme.font.black};
    line-height: 2.3rem;
    letter-spacing: -0.08rem;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 2rem 5rem;
    background: white linear-gradient(180deg, #f2f8fc 0%, #ffffff 100%) 0% 0%
      no-repeat padding-box;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    background-image: url(${background});
    background-position: right bottom;
    height: 20rem;
    background-repeat: no-repeat;
    background-position: center;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    position: relative;
  `}
`;

export const HeaderIcon = styled.div`
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
`;

export const Right = styled.div``;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 4rem;

  margin: 2.4rem auto;

  button {
    background: transparent linear-gradient(180deg, #209fce 0%, #2c7cb8 100%) 0%
      0% no-repeat padding-box;
    height: 8.8rem;
    font-size: 2.4rem;
  }
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const TitleGroup = styled.div`
  font-size: 2rem;
  color: #9eb9c3;
  margin-left: 2.4rem;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UploadDocument = styled.div`
  border-radius: 20px;
  padding: 3rem;
  border-radius: 18px;
  background: var(
    --WhiteBluInverter,
    linear-gradient(180deg, #fff 0%, #f4f9ff 100%)
  );
  box-shadow: 0px 15px 15px 0px rgba(147, 174, 186, 0.25);
`;

export const UploadDocumentLabel = styled.p`
  font-size: 2rem;
  color: #8ab3b7;
  font-weight: bold;
`;

export const UploadDocumentTop = styled.div`
  display: flex;
  gap: 0.7rem;
  margin-bottom: 1rem;
`;

export const UploadDocumentExamples = styled.p`
  color: #9eb9c3;
  margin-left: 1rem;
  margin-bottom: 2.3rem;
`;

export const UploadDocumentBottom = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const UploadDocumentAction = styled.div`
  border-radius: 21px;
  background: #fff;
  box-shadow: 0px 2px 15px 0px rgba(136, 162, 175, 0.25);
  flex-basis: 48%;
  padding: 4rem;
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: none;
  }
  &:active {
    opacity: 0.5;
    transition: 0.3s;
  }
`;

export const UploadDocumentSelected = styled.p`
  margin-left: 1rem;
  margin-top: 2.3rem;
  font-weight: bold;
  font-size: 1.5rem;

  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ImagePreview = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 1rem;
`;

export const Mask = styled.div`
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100vw;
  height: 100vh;
  background-color: ${rgba("#000", 0.5)};

  &:after {
    content: "";
    border-radius: 1rem;
    padding: 2rem;
    width: 70%;
    height: 50%;
    margin-bottom: 2rem;
    border: 2px dashed #fff;

    background-color: ${rgba("#fff", 0.2)};

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  padding: 1em;

  video {
    height: 100vh;
    object-fit: cover;
  }
`;

export const ModalActions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  position: sticky;
  bottom: 0;
  left: 0;

  button {
    justify-content: center;
  }
`;

export const InlineInput = styled.div`
  width: 100%;
  display: flex;
  gap: 0.4em;
`;

export const Document = styled.select`
  padding: 1em;
  border-radius: 120px;
  border: none;
  box-shadow: 0px 9px 20px #88a2af48;
  border: 0.1rem solid transparent;
  border-radius: 35px;
  padding: 2rem 3.5rem;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  transition: all 0.2s linear;
  color: #2b90c2;
`;
export const Icon = styled.img.attrs(({ name }) => ({
  src: `/images/bluemed/${name}.svg`,
}))``;
