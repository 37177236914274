import styled, { css } from 'styled-components';

import { Container as CallToAction } from '../../../components/CallToAction/styles';
import { Container as TitleContainer } from '../../../components/Title/styles';

export const Container = styled.div`
  background: transparent linear-gradient(180deg, #f2f8fc 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
  padding: 0rem 3rem 13rem 3rem;
`;

export const Presentation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

export const IconBox = styled.div`
  object-fit: cover;
  display: flex;
  width: 43px;
  height: 43px;
  padding: 1px;
  border-radius: 39px;
  background: #dfedf4;
`;

export const LeftIcon = styled.img``;

export const PresentationImage = styled.img``;

export const Header = styled.div`
  width: 100%;
  margin: 0 auto;
  border-radius: 0px 0px 41px 41px;
  background: var(--WhiteBlue, linear-gradient(180deg, #f2f8fc 0%, #fff 100%));
  box-shadow: 0px 0px 30px 0px rgba(226, 236, 237, 0.5);
  padding: 1em 3em;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Grid = styled.div`
  width: 100%;
  padding: 2em;
  margin: 0 auto;
`;

export const Title = styled(TitleContainer)`
  font-size: 3.5rem;
  padding-bottom: 0;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: 2rem;
    line-height: 1.5rem;
    font-weight: normal;
    color: ${theme.colors.gray};
    font-family: ${theme.font.thin};
    margin-bottom: 2rem;
  `}
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3em;
  gap: 1rem;
  position: relative;
`;

export const Item = styled.div`
  ${({ isSelected, isRight }) => css`
    background: transparent linear-gradient(180deg, #acc9d3 0%, #88a2af 100%) 0%
      0% no-repeat padding-box;
    border-radius: 60px;
    padding: 1.5rem 2.1rem;
    font-size: 2rem;
    color: #fff;
    position: relative;

    display: flex;
    align-items: center;
    gap: 1.5rem;

    transition: 0.3s;

    ${isSelected &&
    !isRight &&
    css`
      background: transparent linear-gradient(180deg, #d7455c 0%, #ae3a50 100%)
        0% 0% no-repeat padding-box;

      &::before {
        background-image: url(/images/icon/check-red.svg);
      }
    `}

    ${isSelected &&
    isRight &&
    css`
      background: transparent linear-gradient(180deg, #209fce 0%, #2c7bb7 100%)
        0% 0% no-repeat padding-box;

      &::before {
        background-image: url(/images/icon/check-blue.svg);
      }
    `}

    &::before {
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      background-color: #fff;
      border-radius: 100%;
      transition: 0.3s;
    }

    ${isSelected &&
    css`
      &::before {
        transition: 0.3s;
        width: 3rem;
        height: 3rem;
        background-color: #fff;
        margin-top: -1rem;
        margin-bottom: -1rem;
        background-repeat: no-repeat;
        background-position: center center;
      }
    `}

    &:active {
      opacity: 0.7;
      transition: 0.3s;
    }
  `}
`;

export const Action = styled.div`
  position: fixed;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);

  ${CallToAction} {
    width: 100%;

    button {
      width: 60vw;
    }
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;
