import { useNavigate } from 'react-router-dom'
import Button from '../Button'
import * as S from './styles'

const FeedbackFinishCall = ({ name }) => {
  const navigate = useNavigate()
  
  return (
    <S.Feedback>
      <S.Header>
        <S.Seal />
        <S.Logo />
      </S.Header>

      <S.Content>
        <S.Icon />
        <S.Title>Parabéns {name}, <br /> Por esse novo passo <br /> no caminho da sua <br /> jornada legal!</S.Title>

        <S.Description>Continue sua jornada <br /> em busca de uma saúde melhor</S.Description>

        <Button onClick={() => navigate('/profile')}>Voltar para Jornada</Button>

        <S.Copyright>2023 © Blue Mëd</S.Copyright>
      </S.Content>
    </S.Feedback>
  )
}

export default FeedbackFinishCall
