import styled, { css } from 'styled-components';

export const Container = styled.button`
  ${({ theme, block, variant, size }) => css`
    border: none;

    width: 100%;
    height: auto;
    background: transparent linear-gradient(180deg, #00abd1 0%, #0260a9 100%) 0%
      0% no-repeat padding-box;
    font-family: ${theme.font.bold};
    border-radius: 120px;
    padding: 1rem 2.5rem;
    font-size: 21px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.7;

      svg {
        fill: #fff;
      }
    }
    &:active {
      opacity: 0.7;
      transition: ${theme.transition};
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    ${block &&
    css`
      width: 100%;
    `}

    ${size === 'large' &&
    css`
      font-size: 21px;
      padding: 0 2em;
      min-height: 65px;
    `}

    ${variant === 'grey' &&
    css`
      border-radius: 39px;
      background: var(--Grey, linear-gradient(180deg, #ACC9D3 0%, #88A2AF 100%));
      box-shadow: 0px 2px 15px 0px rgba(226, 236, 237, 0.50);
border: none;
      color: #fff;

      border: none;

      font-family: ${theme.font.regular};


      &:hover {
      background: transparent linear-gradient(180deg, #00abd1 0%, #0260a9 100%)
        0% 0% no-repeat padding-box;
        border: none;
      color: #fff;
    `}

    ${variant === 'outline' &&
    css`
      background: none;
      border: 0.2rem solid #2b90c1;
      color: #2b90c1;
      

      &:hover {
      background: transparent linear-gradient(180deg, #00abd1 0%, #0260a9 100%)
        0% 0% no-repeat padding-box;
        border: 0.2rem solid #2b90c1;
      color: #fff;
 
    `}


    ${size === 'large' &&
    css`
      font-size: 21px;
      padding: 0 2em;
      min-height: 65px;
      border: none;
      width: 100% !important;
    `}

    ${size === 'small' &&
    css`
      font-size: 16px;
      padding: 1rem 2rem;
    `}

    ${size === 'medium' &&
    css`
      font-size: 21px;
    `}
  `}
`;

export const Icon = styled.img.attrs(({ icon, iconColor, iconSrc }) => ({
  src: iconSrc || `/images/icon/arrow-${icon}-${iconColor}.svg`,
  alt: `Arrow ${icon}`,
}))``;
