// AttachDocuments.js or AttachDocuments.jsx
import React, { useCallback } from "react";
import Swal from "sweetalert2";
import * as S from "./styles";
import { httpService } from "../../services/axios";

const AttachDocuments = ({ title, id }) => {
  const handleGetDocuments = useCallback(async () => {
    console.log("caiu na vila");
    try {
      Swal.fire({
        title: "Buscando arquivo...",
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false,
      });
      const { data } = await httpService.get(
        `/dashboard/download-receipt/${id}`
      );

      const { file } = data;
      Swal.close();
      window.open(file);
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  return (
    <S.DocumentCard onClick={handleGetDocuments}>
      <S.DocumentHeader>
        <S.DocumentTitle>{title}</S.DocumentTitle>
        <S.IconsContainer>
          {/* Icons or buttons for actions */}
          {/* <span onClick={() => {}}>🔍 View</span> */}
          {/* <span onClick={onDownload}>⬇️ Download</span> */}
        </S.IconsContainer>
      </S.DocumentHeader>
      <S.DocumentContent>
        Baixar {title}
        {/* <S.DocumentThumbnail src={thumbImage} alt='Document thumbnail' /> */}
      </S.DocumentContent>
    </S.DocumentCard>
  );
};

export default AttachDocuments;
