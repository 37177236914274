import styled, { css } from 'styled-components';

import { Container as TitleStyled } from '../../components/Title/styles';

export const Container = styled.div`
  ${({ hide }) => css`
    transform: translate(0, 0);
    transition: 0.8s;

    ${hide &&
    css`
      transform: translate(0, -100%);
      transition: 0.5s;
    `}
  `}
`;

export const Header = styled.div`
  width: 100%;
  padding: 2em 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem 2rem 2rem;
  background: transparent linear-gradient(180deg, #f2f8fc 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 9px 21px #06626e3b;
  border-radius: 0px 0px 45px 45px;
  position: relative;
`;

export const Presentation = styled.img.attrs({
  src: '/images/home/bluemed-banner.png',
  alt: 'Presentation',
})`
  margin-top: 2em;
  margin-bottom: -2em;
  max-width: 80%;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem 0em;
`;

export const Title = styled(TitleStyled)`
  font-size: 45px;
  line-height: 0.3em;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-weight: normal;
    margin-top: 1em;
    color: ${theme.colors.gray};
    font-family: ${theme.font.regular};
    color: #7b7b7b;
    font-size: 21px;
    line-height: 107%; /* 22.47px */
  `}
`;

export const Bottom = styled.div`
  width: 90%;
  margin: 2em auto 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Icon = styled.img.attrs({
  src: '/images/home/doubleIcon.png',
  alt: 'double-icon',
})`
  position: relative;
  top: 12px;
`;
