import { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { TOKEN_NAME, PROVIDER_NAME } from '../../env'

const AuthGoogle = () => {
  const [params] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const id_token = params.get('id_token')

    if(id_token) {
      localStorage.setItem(TOKEN_NAME, id_token)
      localStorage.setItem(PROVIDER_NAME, 'google')
      navigate('/profile')
    }
  }, [params])

  return "redirecionando..."
}

export default AuthGoogle
