import styled, { css } from 'styled-components';

import { Container as CallToAction } from '../../components/CallToAction/styles';
import { Container as TitleContainer } from '../../components/Title/styles';

export const Container = styled.div`
  background: transparent linear-gradient(180deg, #f2f8fc 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
  padding: 0rem 3rem 13rem 3rem;
`;

export const Presentation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4em;
  margin-bottom: 2em;
`;

export const PresentationImage = styled.img``;

export const Grid = styled.div`
  width: 85%;
  margin: 0 auto;

  ${({ top }) =>
    top &&
    css`
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;
    `}
`;

export const Title = styled(TitleContainer)`
  font-size: 3.5rem;
  line-height: 3rem;
  padding-bottom: 0;
  margin-bottom: 1rem;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: 2rem;
    line-height: 1.1em;
    font-weight: normal;
    color: ${theme.colors.gray};
    font-family: ${theme.font.thin};
    margin-bottom: 2rem;
  `}
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  z-index: 1;
`;

export const Item = styled.div`
  ${({ checked, theme }) => css`
    cursor: pointer;
    background: ${checked
      ? 'linear-gradient(360deg, #31769B 0%, #40ABCB 100%)'
      : '#fff linear-gradient(360deg, #8DA1AB 0%, #C4D0D7  100%)'};
    border-radius: 21px;
    padding: 2.5rem 3rem;
    font-size: 1.4rem;
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    transition: 0.3s;
    font-family: ${theme.font.regular};
    font-weight: 600;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 75%;
      height: 100%;
      transition: 0.3s;
      background: ${checked
        ? 'linear-gradient(180deg, #2CB7D7 0%, #2D7CB8 100%)'
        : 'linear-gradient(180deg, #C8D6DD 0%, #A7BCC7 100%)'};

      box-shadow: 0px 3px 6px #0000000d;
      border-radius: 21px 0px 37px 21px;
    }
  `}
`;

export const ItemText = styled.span`
  position: relative;
  line-height: 21px;
  font-size: 18px !important;
`;

export const Check = styled.div`
  ${({ checked }) => css`
    position: absolute;
    right: 1.6rem;
    top: 50%;
    transform: translateY(-50%);

    width: 4.5rem;
    height: 2.2rem;
    background: ${checked ? '#16AFCB' : '#7E8F9D'};
    transition: 0.3s;
    border-radius: 16px;

    &:after {
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      background-color: #fff;
      border-radius: 100%;
      position: absolute;
      right: ${checked ? '0.3rem' : '60%'};
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      transition: 0.3s;
    }
  `}
`;

export const Action = styled.div`
  ${CallToAction} {
    width: 80vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    button {
      width: 100%;
    }
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

export const Bottom = styled.div`
  position: relative;
  padding-top: 5.4rem;

  &:before {
    content: '';
    width: calc(100% + 6rem);
    height: 100%;

    top: -5.3rem;
    left: -3rem;

    position: absolute;
    z-index: 0;
    background: transparent
      linear-gradient(180deg, #e5f0f8 0%, #f0f5f7 51%, #ffffff 100%) 0% 0%
      no-repeat padding-box;
    border-radius: 236px 0px 0px 0px;
  }
`;

export const BottomContent = styled.div`
  position: relative;
`;

export const Pricing = styled.div`
  border-radius: 27px;
  background: #fff;
  box-shadow: 0px 6px 15px #114d894b;
  margin: 3rem auto 4rem;
`;

export const PricingTop = styled.div`
  padding: 1.7rem 2.7rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const PricingItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const PricingIcon = styled.img.attrs({
  src: '/images/icon/rounded.svg',
})`
  width: 1.5rem;
  height: 1.5rem;
`;
export const PricingTitle = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.font.regular};
    font-weight: 400;
    font-size: 2rem;
    color: #809fb8;
    margin-top: 0.4rem;
  `}
`;

export const PricingBottom = styled.div`
  border-top: 1px dashed #9bc9de;
  padding: 2rem 2.7rem;
  background: transparent linear-gradient(180deg, #2bb8d9 -200%, #fff 100%) 0%
    0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0px 0px 27px 27px;
`;
export const PricingPrice = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-end;
    font-family: ${theme.font.regular};
    color: #2c9eca;
  `}
`;

export const PricingPriceCurrency = styled.div`
  font-size: 2.6rem;
  margin-right: 0.5rem;
`;
export const PricingPriceMax = styled.div`
  font-size: 3.3rem;
  font-weight: 600;
  line-height: 1;
`;
export const PricingPriceMin = styled.div`
  font-size: 2.8rem;
  font-weight: 600;
`;

export const PricingPricePeriod = styled.div`
  color: #809fb8;
  font-size: 2.1rem;
  font-weight: 600;
`;

export const UserLoggedIn = styled.div`
  font-weight: 600;
  color: #7f9eb7;
  font-size: 1.5rem;

  box-shadow: 0px 3px 9px #7f9eb745;
  border-radius: 35px;
  padding: 1.3rem 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 0.8rem;

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    line-height: 1.5rem;
    margin-bottom: -0.4rem;
  }
`;

export const IconBox = styled.div`
  object-fit: cover;
  display: flex;
  width: 43px;
  height: 43px;
  padding: 1px;
  border-radius: 39px;
  background: #dfedf4;
`;

export const LeftIcon = styled.img``;

export const Header = styled.div`
  width: 100%;
  margin: 0 auto;
  border-radius: 0px 0px 41px 41px;
  background: var(--WhiteBlue, linear-gradient(180deg, #f2f8fc 0%, #fff 100%));
  box-shadow: 0px 0px 30px 0px rgba(226, 236, 237, 0.5);
  padding: 1em 3em;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
