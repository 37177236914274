import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ show }) => css`
    position: fixed;
    top: -120%;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${rgba('#fff', 0.5)};
    opacity: 1;
    backdrop-filter: blur(14px);
    -webkit-backdrop-filter: blur(14px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 4em;
    transition: 0.3s;

    ${show &&
    css`
      transition: 0.3s;
      top: 0;
      opacity: 1;
      visibility: visible;
      z-index: 3;
    `}
  `}
`;

export const Cover = styled.img.attrs({ src: '/images/icon/images.svg' })`
  margin: 20rem auto;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    box-shadow: 0px 9px 21px #00000029;
    padding: 4rem 3rem;
    background-color: #fff;

    border-radius: 25px;
    text-align: center;

    margin: 0 auto 1.6rem auto;
    h1 {
      font-size: 2.3rem;
      font-family: ${theme.font.poppins};
      font-weight: 600;
    }
  `}
`;

export const Icon = styled.img.attrs({ src: '/images/icon/add-alert.svg' })``;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: 1.3rem;
    font-weight: normal;
    color: ${theme.colors.gray};
    font-family: ${theme.font.poppins};
  `}
`;

export const Button = styled.div`
  ${({ theme }) => css`
    background: transparent linear-gradient(180deg, #d7455c 0%, #ae3a50 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 9px 21px #00000029;
    border-radius: 25px;
    color: #fff;
    padding: 3.4rem 5.3rem;
    font-family: ${theme.font.poppins};
    font-weight: 600;
    font-size: 1.8rem;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;
    transition: 0.3s;
    &:active {
      transition: 0.3s;
      transform: scale(0.98);
    }
  `}
`;

export const ButtonIcon = styled.img.attrs({
  src: '/images/icon/mood-bad.svg',
})``;
