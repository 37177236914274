import { useCallback, useEffect, useRef, useState } from 'react'
import { useStripe, useElements, CardElement, PaymentElement, Elements, LinkAuthenticationElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom'

import * as S from './styles'
import { httpService } from '../../services/axios';
import { API_URL_MIDDLEWARE } from '../../env';
import Feedbacks from '../Feedbacks';
import Swal from 'sweetalert2';

let interval = null

const CardOrPix = ({ action }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const navigate = useNavigate()

  const [pixInfo, setPixInfo] = useState(null)
  console.log("pixInfo", pixInfo)

  const hangleGetPixInfo = async () => {
    try {
      const { data } = await httpService.get(`/payment/pix`)
      setPixInfo(data)

    } catch (error) {
      console.log('error', error)
    }
  }


  const handleCheckPaymentPix = useCallback(async () => {
    try {
      const { data } = await httpService.post(`/payment/check`, {
        id: pixInfo.id,
      })
      
      if(data.paid) {
        await Swal.fire("Sucesso!", "Pagamento realizado com sucesso.", "success")
        navigate(`/payment/success/pix?redirect_status=succeeded&payment_intent=${data.paymentId}&payment_intent_client_secret=${data.customerId}`)
      }

    } catch (error) {
      console.log('error', error)
    }
  }, [pixInfo])

  const handleStartCheckPaymentPix = () => {
    interval = setInterval(() => {
      handleCheckPaymentPix()
    }, 10000) // meio segundo
  }

  useEffect(() => {
    hangleGetPixInfo()

    return () => {
      interval && clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if(pixInfo) {
      handleStartCheckPaymentPix()
    }

    return () => {
      interval && clearInterval(interval)
    }
  }, [pixInfo])


  const [method, setMethod] = useState('card')

  const handleMethod = (method) => {
    setMethod(method)
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment/success/card`,
      },
    });

    if (error) {
      setPaymentError(!!error);

      setTimeout(() => {
        setPaymentError(false);
      }, 3000)
    }

    setIsLoading(false);
  }

  return (
    <>
      <Feedbacks
        show={paymentError}
        type='secondary'
        icon='bad-answer'
        title='Pagamento Recusado'
        description={() => (<>Lamentamos, mas o <br /> pagamento não foi processado, <br /> confira todos os dados e <br /> tente novamente</>)}
      />

      <S.Container>
        <S.Choose>
          <S.ChooseItem active={method === 'card'} onClick={() => handleMethod('card')}>
            <S.ChooseBg>
              <S.ChooseIcon icon={`card${method === 'pix' ? '-gray' : ''}`} width='3.4rem' height='2.7rem' />
            </S.ChooseBg>
            <S.ChooseTitle>Cartão de crédito</S.ChooseTitle>
          </S.ChooseItem>

          <S.ChooseItem active={method === 'pix'} onClick={() => handleMethod('pix')}>
            <S.ChooseBg>
              <S.ChooseIcon icon={`pix${method === 'card' ? '-gray' : ''}`} width='4.1rem' height='4.1rem' />
            </S.ChooseBg>
            <S.ChooseTitle>PIX</S.ChooseTitle>
          </S.ChooseItem>
        </S.Choose>

        <S.Form onSubmit={handleSubmit}>

          {
            method === 'card' && (
              <PaymentElement />
            )
          }

          {
            method === 'pix' && (
              <p>
                <img src={`data:image/jpeg;base64,${pixInfo?.encodedImage}`} alt="QR Code" />

                <code>{pixInfo?.code}</code>
              </p>
            )
          }

          <S.Disclaimer>
            Ao confirmar sua subscrição, <br /> você permite que <b>Bluemed®</b> cobre seu cartão <br /> por esse pagamento e pagamentos futuros <br /> de acordo com as condições.
          </S.Disclaimer>
          {method === 'card' && (
            <>
              {action && action(isLoading || !stripe || !elements, isLoading)}
            </>
          )}
        </S.Form>

      </S.Container>
    </>
  )
}

export default CardOrPix
