import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FiUser } from 'react-icons/fi';
import { IoCloseCircle } from 'react-icons/io5';
import { PiPassword } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { PROVIDER_NAME, TOKEN_NAME } from '../../env';
import { httpService } from '../../services/axios';
import Button from '../Button';
import Input from '../Input';
import Logo from '../Logo';

import { schema } from './schema';
import * as S from './styles';

const socials = [
  {
    id: 'google',
    icon: 'google',
  },
];

const Login = ({
  show,
  toggleLogin,
  createRedirect = '/contra-indications',
  loginRedirect,
  withHeader = true,
  specialist = true,
  onlyEnterButton = false,
  fullPage = true,
}) => {
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = async ({ email, password }) => {
    try {
      const { data: response } = await httpService.post('/auth', {
        email,
        password,
      });

      if (!response.jwt) throw new Error('Usuário e/ou senha incorreto.');

      localStorage.setItem(TOKEN_NAME, response.jwt);
      localStorage.setItem(PROVIDER_NAME, 'local');
      localStorage.setItem('userInfo', JSON.stringify(response.user));

      if (loginRedirect === 'same-page') {
        window.location.reload();
        return;
      }

      navigate(loginRedirect || createRedirect);
    } catch (error) {
      Swal.fire('Oops!', 'Usuário e/ou senha incorreto.', 'warning');
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  const handleNavigate = () => {
    toggleLogin();
    navigate(createRedirect);
  };

  return (
    <S.Wrapper show={show} fullPage={fullPage}>
      {withHeader && (
        <S.Group show={show}>
          <S.TitleGroup>
            Agora você pode, <br /> é seu direito
          </S.TitleGroup>

          <S.DescriptionGroup>
            Agende suas consultas sem fila, <br></br>sem agendamento, onde você
            estiver.
          </S.DescriptionGroup>
        </S.Group>
      )}

      <S.Container fullPage={fullPage}>
        <S.Top>
          <Logo type='bluepharm' height='3rem' />
          <S.Title>
            Login vinculado com o <br /> Bluemed™ Cannabis Company
          </S.Title>
          <S.CloseHeader onClick={toggleLogin}>
            <IoCloseCircle size={20} color='#B1C2CC' />
          </S.CloseHeader>
        </S.Top>
        <S.Socials>
          {/* <S.ActionTitle>Entrar com</S.ActionTitle> */}
          <S.Icons>
            {socials.map((social) => (
              // <a
              //   href={`${API_URL}/connect/${social.id}`}
              //   key={social.id}
              //   rel='noreferrer'
              //   style={{ textDecoration: 'none' }}
              // >
              <S.IconSocialBtn>
                Entrar com Google
                <S.Icon icon={social.icon} />
              </S.IconSocialBtn>
            ))}
          </S.Icons>
          <S.ActionTitle>ou acesse com seu email e senha</S.ActionTitle>
        </S.Socials>
        <FormProvider {...methods}>
          <S.Form onSubmit={methods.handleSubmit(handleLogin)}>
            {/* <S.ActionTitle>ou</S.ActionTitle> */}

            <S.Fields>
              <Input
                placeholder='Email'
                styling='default'
                name='email'
                icon={<FiUser size={20} />}
                error={methods.formState.errors.email?.message}
              />
              <Input
                placeholder='Senha'
                type='password'
                name='password'
                icon={<PiPassword size={20} />}
                error={methods.formState.errors.password?.message}
              />
            </S.Fields>

            <S.ForgotPassword href='/forgot-password'>
              Esqueceu a Senha?
            </S.ForgotPassword>

            <S.Actions>
              {!onlyEnterButton && <S.Close onClick={toggleLogin} />}
              <Button
                disabled={!methods.formState.isValid}
                variant='outline'
                icon='right'
                size='large'
                block
                iconColor='blue'
                isLoading={loading}
                isLoadingMessage={'Entrando...'}
              >
                Entrar
              </Button>
            </S.Actions>
          </S.Form>
        </FormProvider>

        <S.Bottom onClick={handleNavigate}>
          Não tem conta?{' '}
          <S.SignUp>
            {specialist ? 'Crie durante o atendimento' : 'Criar aqui'}
          </S.SignUp>
        </S.Bottom>
      </S.Container>
    </S.Wrapper>
  );
};

export default Login;
