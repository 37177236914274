import { useNavigate } from 'react-router-dom';
import Logo from '../Logo';
import * as S from './styles';

const Header = () => {
  const navigate = useNavigate();
  return (
    <S.Container>
      <S.Header>
        <S.IconBox onClick={() => navigate(-1)}>
          <S.LeftIcon src={`/images/icon/left.svg`} />
        </S.IconBox>
        <Logo height='2.5rem' />
      </S.Header>
    </S.Container>
  );
};

export default Header;
