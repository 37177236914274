// MacroAppointments.js or MacroAppointments.jsx
import React from 'react';
import * as S from './styles';

const MacroAppointments = ({
  date,
  time,
  doctorName,
  doctorImage,
  crm,
  speciality,
}) => {
  return (
    <S.AppointmentCard>
      <S.AppointmentInfo>
        <S.DateAndTime>
          <S.Icon src='/icon/account/user-menu.svg' alt='Calendar Icon' />
          {`${date} às ${time}`}
        </S.DateAndTime>
        <S.DoctorInfo>
          <S.DoctorImage src={doctorImage} alt={`Dr(a). ${doctorName}`} />
          <S.DoctorDetails>
            <S.DoctorName>{doctorName}</S.DoctorName>
            <S.Speciality>
              <S.Icon src='/icon/account/user-menu.svg' alt='Calendar Icon' />
              <S.SpecialityText>{`CRM ${crm}`}</S.SpecialityText>
              <S.Icon src='/icon/account/user-menu.svg' alt='Calendar Icon' />
              <S.SpecialityText>{speciality}</S.SpecialityText>
            </S.Speciality>
          </S.DoctorDetails>
        </S.DoctorInfo>
      </S.AppointmentInfo>
      <S.ArrowIcon>→</S.ArrowIcon>
    </S.AppointmentCard>
  );
};

export default MacroAppointments;
