import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import CallToAction from '../../components/CallToAction';
import Login from '../../components/Login';
import Logo from '../../components/Logo';
import * as S from './styles';

function Home() {
  const [showLogin, setShowLogin] = useState(false);

  const handleShowLogin = () => {
    document.querySelector('body').classList.toggle('-no-overflow');
    setShowLogin((prev) => !prev);
  };
  const navigate = useNavigate();

  const handleNavigate = () => navigate('/contra-indications');

  return (
    <>
      <S.Container hide={showLogin}>
        <S.Top>
          <S.Header>
            <Logo />
          </S.Header>
          <S.Presentation />
          <S.Group>
            <S.Title>
              Agora você pode, <br></br>é seu <S.Icon /> direito.
            </S.Title>

            <S.Description>
              Agende suas consultas, sem filas <br></br>sem atrasos, de onde
              você estiver.
            </S.Description>
          </S.Group>

          <CallToAction onClick={handleNavigate}>
            Fale com o Médico
          </CallToAction>
        </S.Top>

        <S.Bottom>
          <Button
            variant='outline'
            size='large'
            icon='top'
            iconColor='blue'
            onClick={handleShowLogin}
          >
            Acessar minha conta
          </Button>
        </S.Bottom>
      </S.Container>
      <Login
        show={showLogin}
        toggleLogin={handleShowLogin}
        loginRedirect='/profile'
      />
    </>
  );
}

export default Home;
