import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TOKEN_NAME } from '../../env';

function useAuthentication(isRedirect = true, isPublic) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isPublic) {
      const token = localStorage.getItem(TOKEN_NAME);
      if (token) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        isRedirect && navigate('/login');
      }
    }
  }, [navigate]);

  return isLoggedIn;
}

export default useAuthentication;
