import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import { useNavigate } from 'react-router-dom';
import CallToAction from '../../components/CallToAction';
import CardOrPix from '../../components/CardOrPix';
import Header from '../../components/Header';
import Login from '../../components/Login';
import { STRIPE_PUBLIC_KEY } from '../../env';
import useAuthentication from '../../hooks/useAuthentication';
import { httpService } from '../../services/axios';
import * as S from './styles';

const redirectTerm = {
  TERM_OF_VERACITY: 'term-of-veracity',
  TERM_OF_RESPONSABILITY: 'term-of-responsibility',
};

function TermsAndAccept() {
  const [veracity, setVeracity] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [responsability, setResponsability] = useState(false);
  const isLoggedIn = useAuthentication(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.querySelector('body').classList.remove('-no-overflow');
  }, []);

  const handleCreateAmanese = async () => {
    const anamnesesId = localStorage.getItem('anamnesesId');
    console.log('anamnesesId', anamnesesId);
    if (anamnesesId !== 'null') return;

    try {
      setIsLoading(true);
      Swal.fire({
        title: 'Aguarde',
        html: 'Estamos buscando os documentos a serem assinados...',
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false,
      });
      const screenings = JSON.parse(localStorage.getItem('screening'));

      const { data } = await httpService.post(`/anamneses`, {
        screenings,
      });

      localStorage.setItem('anamnesesId', data.anamnesesId);

      return data.anamnesesId;
    } catch (error) {
      Swal.fire('Oops', 'Houve um erro ao criar a anamnese', 'warning');
    } finally {
      setIsLoading(!isLoading);
      Swal.close();
    }
  };

  const handleShowLogin = () => {
    document.querySelector('body').classList.toggle('-no-overflow');
    setShowLogin((prev) => !prev);
  };

  const handleSelectTerm = (term) => {
    localStorage.setItem('currentTerm', term);
  };

  const navigateToNextPage = (nextPage) => {
    navigate(nextPage);
  };

  const openLogginWhenNotLoggedIn = useCallback(() => {
    setShowLogin(!isLoggedIn);
  }, [isLoggedIn]);

  const verifyFacedetection = async () => {
    // const { data } = await httpService.get('/user/upload/exists/facedetection');

    // return data;

    return true;
  };

  const handleCheckVeracity = useCallback(async () => {
    handleSelectTerm('TERM_OF_VERACITY');
    openLogginWhenNotLoggedIn();

    if (isLoggedIn && !veracity) {
      const isFaceDetected = await verifyFacedetection();

      await handleCreateAmanese();

      return isFaceDetected
        ? navigateToNextPage(`/terms/${redirectTerm['TERM_OF_VERACITY']}`)
        : navigateToNextPage(`/face-detection`);
    }

    setVeracity(isLoggedIn);
  }, [isLoggedIn]);

  const handleCheckResponsability = useCallback(async () => {
    handleSelectTerm('TERM_OF_RESPONSABILITY');
    openLogginWhenNotLoggedIn();

    if (isLoggedIn && !responsability) {
      const isFaceDetected = await verifyFacedetection();
      await handleCreateAmanese();
      return isFaceDetected
        ? navigateToNextPage(`/terms/${redirectTerm['TERM_OF_RESPONSABILITY']}`)
        : navigateToNextPage(`/face-detection`);
    }

    setResponsability(isLoggedIn);
  }, [isLoggedIn]);

  const handleCheckTerms = useCallback(async () => {
    const anamnesesId = localStorage.getItem('anamnesesId');

    if (anamnesesId && anamnesesId !== 'null') {
      try {
        setIsLoading(true);
        Swal.fire({
          title: 'Aguarde',
          html: 'Estamos gerando verificando os termos assinados...',
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const { data: response } = await httpService.get(
          `/anamneses/${anamnesesId}`,
        );
        setIsLoading(false);

        const isVeracitySigned =
          response?.attributes?.term?.termOfVeracitySigned;
        const isResponsabilitySigned =
          response?.attributes?.term?.termOfResponsibilitySigned;

        setVeracity(isVeracitySigned);
        setResponsability(isResponsabilitySigned);
      } catch (error) {
        setIsLoading(false);
      } finally {
        Swal.close();
      }
    }
  });

  useEffect(() => {
    handleCheckTerms();
  }, []);

  const getClientSecret = useCallback(async () => {
    const { data } = await httpService.post(`/payment`);

    setClientSecret(data);
  }, []);

  useEffect(() => {
    getClientSecret();
  }, []);

  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

  const renderAction = (disabled, isLoading) => {
    return (
      <S.Action>
        <CallToAction
          size='small'
          type='submit'
          disabled={disabled}
          isLoading={isLoading}
          isLoadingMessage={'Processando pagamento'}
        >
          {!isLoading ? 'Pagar' : 'Processando pagamento'}
        </CallToAction>
      </S.Action>
    );
  };

  return (
    <>
      {' '}
      <Header />
      <S.Container>
        {/* <S.Grid top>
          {isLoggedIn && (
            <S.UserLoggedIn>
              <FaUser color='#2CB7D8' />

              <span>Minha conta</span>
            </S.UserLoggedIn>
          )}
        </S.Grid> */}

        <S.Presentation>
          <S.PresentationImage src={`/images/flow/documents.svg`} />
        </S.Presentation>
        <S.Grid>
          <S.Title>Confira os termos.</S.Title>
          <S.Description>
            Você sente ou foi diagnosticado com algum dos sintomas abaixo?
          </S.Description>

          <S.List>
            <S.Item checked={veracity} onClick={handleCheckVeracity}>
              <S.ItemText>
                Termo de veracidade<br></br>& Procuração da Anvisa
              </S.ItemText>
              <S.Check checked={veracity} />
            </S.Item>
            {/* 
            <S.Item
              checked={responsability}
              onClick={handleCheckResponsability}
            >
              <S.ItemText>Termo de Responsabilidade</S.ItemText>
              <S.Check checked={responsability} />
            </S.Item> */}
          </S.List>
        </S.Grid>

        {isLoggedIn && veracity && (
          <S.Bottom>
            <S.BottomContent>
              <S.Grid>
                <S.Title>
                  Paga e Garanta <br /> sua Jornada Legal
                </S.Title>

                <S.Pricing>
                  <S.PricingTop>
                    <S.PricingItem>
                      <S.PricingIcon />

                      <S.PricingTitle>Consulta Médica</S.PricingTitle>
                    </S.PricingItem>

                    <S.PricingItem>
                      <S.PricingIcon />

                      <S.PricingTitle>Registro na Anvisa</S.PricingTitle>
                    </S.PricingItem>

                    <S.PricingItem>
                      <S.PricingIcon />

                      <S.PricingTitle>Acesso a medicamentos</S.PricingTitle>
                    </S.PricingItem>
                  </S.PricingTop>

                  <S.PricingBottom>
                    <S.PricingPrice>
                      <S.PricingPriceCurrency>R$</S.PricingPriceCurrency>
                      <S.PricingPriceMax>89</S.PricingPriceMax>
                      <S.PricingPriceMin>,90</S.PricingPriceMin>
                    </S.PricingPrice>

                    <S.PricingPricePeriod>à vista</S.PricingPricePeriod>
                  </S.PricingBottom>
                </S.Pricing>

                {clientSecret && (
                  <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <CardOrPix
                      action={renderAction}
                      stripePromise={stripePromise}
                    />
                  </Elements>
                )}
              </S.Grid>
            </S.BottomContent>
          </S.Bottom>
        )}
      </S.Container>
      <Login
        show={showLogin}
        toggleLogin={handleShowLogin}
        withHeader={false}
        specialist={false}
        fullPage={false}
        onlyEnterButton={true}
        loginRedirect='same-page'
        createRedirect='/create-account'
      />
    </>
  );
}

export default TermsAndAccept;
