import { useCallback, useEffect, useState } from 'react';
import { TERMS, TOKEN_NAME } from '../../../env';
import { httpService } from '../../../services/axios';
import Template from '../Template';
const ContraIndications = () => {
  localStorage.setItem('medicalAppointmentId', '');
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetListItems = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await httpService.get(
        '/anamneses/flow/contra-indications',
      );
      setItems(data.data);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleGetListItems();

    localStorage.removeItem(TERMS.TERM_OF_VERACITY);
    localStorage.removeItem(TERMS.TERM_OF_RESPONSABILITY);
    localStorage.setItem('anamnesesId', null);
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem('currentTerm');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('medicalAppointmentId');
  }, [handleGetListItems]);

  return (
    <Template
      isLoading={isLoading}
      items={items}
      type='contraindications'
      presentation='contraindications'
      rightsQuestions={[]}
      isContraindication
      title='Contra Indicações'
      description='O tratamento com canabinoides é proscrito para as seguintes condições:'
      alertDescription='É contra indicado o uso de canabinoides para essa condição.'
      buttonText='Entendi'
      nextPage='/screening'
      nextPageTitle='Triagem'
    />
  );
};

export default ContraIndications;
