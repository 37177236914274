import styled, { css } from 'styled-components';

import { Container as Button } from '../../components/Button/styles';

export const Container = styled.div`
  background: white linear-gradient(180deg, #f2f8fc 0%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
  min-height: 100vh;

  padding: 2rem 4rem;

  ${({ blured }) =>
    blured &&
    css`
      filter: blur(23px);
    `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    font-size: 3.5rem;
    line-height: 2.8rem;
  }
`;

export const Icon = styled.img.attrs({ src: '/images/icon/rounded.svg' })`
  width: 25px;
`;

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
`;

export const Content = styled.div`
  font-size: 2.1rem;
  color: #7e7e7e;

  b {
    font-weight: 600;
    color: #707070;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #96a9b9 0% 0% no-repeat padding-box;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  opacity: 0.8;
`;

export const Footer = styled.div``;

export const FooterContent = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;

  ${Button} {
    height: 7.1rem;
    gap: 2rem;

    &:first-of-type {
      background: #88a2af;
      width: 9.2rem;
      justify-content: center;
    }

    &:last-of-type {
      background: transparent linear-gradient(180deg, #209fce 0%, #2c7cb8 100%)
        0% 0% no-repeat padding-box;
      width: calc(100% - 11.2rem);
    }
  }
`;

export const ClickableArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const SignatureArea = styled.div`
  ${({ show }) => css`
    border: 1px dashed #707070;
    border-radius: 14px;
    background-color: #fff;

    position: relative;
    transition: 0.3s;
    cursor: ${show ? 'auto' : 'pointer'};
    height: ${show ? '30rem' : '7rem'};

    &:before {
      content: 'X';
      position: absolute;
      bottom: ${show ? '6.8rem' : '1.3rem'};
      left: 2.4rem;
      font-size: 1.5rem;
      color: #383838;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: ${show ? '6.8rem' : '1.3rem'};
      left: 4.4rem;
      width: calc(100% - 8.8rem);
      height: 1px;
      background: #707070;
    }
  `}
`;

export const UserNameSignature = styled.div`
  text-align: center;
  margin-top: 1rem;
  font-size: 2rem;
  color: #383838;
`;

export const Clear = styled.div`
  color: #707070;
  font-size: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Iframe = styled.iframe`
  border: none;
  border-radius: 1.4rem;
`;
