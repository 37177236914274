import { useEffect, useState } from 'react';
import * as S from './styles'
import Button from '../Button';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Chat = ({ name, roomName, isCustomer, updateSchedule, appointment }) => {
  const [finalized, setFinalized] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if(isCustomer) {
      updateSchedule(null, 'inmeeting', appointment)
    }
  }, [isCustomer])

  async function handleMessage(event) {
    console.log("event???", event)

    console.log("Mensagem recebida do iframe:", event.data);
    if (event.data === "chamada-finalizada" && isCustomer) {
      console.log("É Customer:", isCustomer)
      setFinalized(true)
    }

    if (event.data === "chamada-finalizada" && !isCustomer) {
      Swal.fire({
        title: 'Chamada finalizada',
        html: 'Sua consulta foi finalizada com sucesso!',
        icon: 'success',
        confirmButtonText: 'Ok',
      }).then(async () => {
        await updateSchedule(null, 'done', appointment)
      })
    }
  }

  if (finalized) {
    return (
      <S.Feedback>
        <S.Header>
          <S.Seal />
          <S.Logo />
        </S.Header>

        <S.Content>
          <S.Icon />
          <S.Title>Parabéns {name}, <br /> Por esse novo passo <br /> no caminho da sua <br /> jornada legal!</S.Title>
        
          <S.Description>Continue sua jornada <br /> em busca de uma saúde melhor</S.Description>
        
          <Button onClick={() => navigate('/profile')}>Voltar para Jornada</Button>

          <S.Copyright>2023 © Blue Mëd</S.Copyright>
        </S.Content>
      </S.Feedback>
    )
  }

  return (
    <S.Container>
      <iframe allow="camera; microphone" src={`https://video-app-1929-6295-dev.twil.io?passcode=50509219296295&name=${name}&roomname=${roomName}`} />
    </S.Container>
  )
}

export default Chat
