import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Button from '../../components/Button';
import Feedbacks from '../../components/Feedbacks';
import Title from '../../components/Title';
import { API_URL_MIDDLEWARE, URL_TO_TERM } from '../../env';
import { httpService } from '../../services/axios';
import * as S from './styles';

const Terms = () => {
  const { term } = useParams();
  const navigate = useNavigate();
  const iframe = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [anamnese, setAnamnese] = useState({});
  const [isSigned, setIsSigned] = useState(false);

  const handleGetTermInfo = async () => {
    try {
      setIsLoading(true);
      Swal.fire({
        title: 'Carregando...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const anamnesesId = localStorage.getItem('anamnesesId');

      const { data: response } = await httpService.get(
        `${API_URL_MIDDLEWARE}/anamneses/${anamnesesId}`,
      );
      Swal.close();
      setAnamnese(response);
    } catch (error) {
      Swal.fire({
        title: `Erro ao localizar a anamnese, tente novamente.`,
        icon: 'warning',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyTerm = useCallback(async () => {
    try {
      Swal.fire({
        title: 'Verificando assinatura...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const documentId =
        term === 'term-of-responsibility'
          ? anamnese?.attributes?.term?.idTermOfResponsibility
          : anamnese?.attributes?.term?.idTermOfVeracity;
      const { data: response } = await httpService.get(
        `${API_URL_MIDDLEWARE}/documents/${documentId}`,
      );
      Swal.close();

      const documentIsSigned = response?.status === 'signed';

      if (!documentIsSigned) {
        Swal.fire({
          title: `Termo não assinado, por favor assine o termo para continuar.`,
          icon: 'warning',
        });
      }
      setIsSigned(documentIsSigned);

      if (documentIsSigned) {
        await httpService.put(
          `${API_URL_MIDDLEWARE}/anamneses/${anamnese?.id}`,
          {
            term: {
              id: anamnese.attributes.term.id,
              [term === 'term-of-responsibility'
                ? 'termOfResponsibilitySigned'
                : 'termOfVeracitySigned']: true,
            },
          },
        );

        localStorage.setItem(URL_TO_TERM[term], true);

        setTimeout(() => {
          navigate('/terms-and-accept');
        }, 3000);
      }
    } catch (error) {
      setIsSigned(false);
      Swal.fire({
        title: `Erro ao localizar o documento, tente novamente.`,
        icon: 'warning',
      });
    }
  }, [anamnese, term]);

  useEffect(() => {
    handleGetTermInfo();
  }, [term]);

  const TitleTermOfVeracity = () => {
    return <Title>Termo de Veracidade</Title>;
  };

  const TitleTermOfResponsibility = () => {
    return <Title>Termo de Responsabilidade</Title>;
  };

  const types = {
    'term-of-veracity': <TitleTermOfVeracity />,
    'term-of-responsibility': <TitleTermOfResponsibility />,
  };

  const transformHttpToHttps = (url) => {
    return url?.replace('http://', 'https://');
  };

  return (
    <>
      <Feedbacks
        show={isSigned}
        title='Assinado com sucesso!'
        description={() => (
          <>
            Você pode baixar o <br /> seu documento original e assinado <br />{' '}
            na área do usuário.
          </>
        )}
      />
      <S.Container blured={isSigned}>
        <S.Header>
          {types[term]}

          <S.Icon />
        </S.Header>

        <S.Contents>
          <S.Iframe
            src={`${transformHttpToHttps(
              term === 'term-of-responsibility'
                ? anamnese?.attributes?.term?.linkTermOfResponsibility
                : anamnese?.attributes?.term?.linkTermOfVeracity,
            )}?lang=pt-br`}
            height='600px'
            ref={iframe}
          />
        </S.Contents>

        <S.Footer>
          <S.FooterContent>
            <Button icon='left' variant='grey' />
            <Button icon='right' onClick={handleVerifyTerm}>
              Próximo
            </Button>
          </S.FooterContent>
        </S.Footer>
      </S.Container>
    </>
  );
};

export default Terms;
