import { FiUserPlus } from "react-icons/fi";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import * as S from "./styles";

import { useCallback, useEffect, useState } from "react";
import { TOKEN_NAME } from "../../env";
import { httpService } from "../../services/axios";

import { STEPS } from "./Components/steps";

import Step1 from "./Components/step1";
import Step2 from "./Components/step2";
import Step3 from "./Components/step3";
import Step4 from "./Components/step4";

const Signup = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const [fieldValue, setFieldValue] = useState({
    step1: {
      name: "",
      whatsapp: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    step2: {
      cpf: "",
      rg: "",
      dateOfBirth: "",
      gender: "",
    },
    step3: {
      cep: "",
      street: "",
      city: "",
      neighborhood: "",
      number: "",
      state: "",
    },
    step4: {},
  });

  useEffect(() => {
    document.querySelector("body").classList.remove("-no-overflow");
    localStorage.removeItem(TOKEN_NAME);
  }, []);

  const getValues = () => {
    const keys = Object.keys(fieldValue);
    let value = {};

    for (let index = 0; index < keys.length; index++) {
      value = {
        ...value,
        ...fieldValue[keys[index]],
      };
    }

    return value;
  };

  const onSubmit = useCallback(
    async (payload) => {
      try {
        Swal.fire({
          title: "Aguarde",
          text: "Estamos cadastrando seu usuário",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });

        const data = { ...getValues(), ...payload };

        setLoading(true);
        const screenings = JSON.parse(localStorage.getItem("screening"));

        const { data: response } = await httpService.post(`/users`, {
          ...data,
          screenings,
        });

        localStorage.setItem("anamnesesId", response.anamnesesId);
        localStorage.setItem(TOKEN_NAME, response.jwt);

        Swal.fire({
          title: "Conta cadastrada com sucesso!",
          text: "Agora você precisará fazer o upload de um documento.",
          icon: "success",
        }).then(() => {
          setCurrentStep(4);
        });
      } catch (error) {
        let message = "Erro ao realizar o cadastro, tente novamente.";

        if (error?.response?.data?.message?.includes("CPF")) {
          message = error?.response?.data?.message;
        }

        Swal.fire({
          title: message,
          icon: "warning",
        });
      } finally {
        setLoading(false);
      }
    },
    [getValues]
  );

  const handleNext = (step, value) => {
    if (step === STEPS.STEP_4) {
      onSubmit({
        ...getValues(),
        ...value,
      });
    } else {
      setCurrentStep(step);
    }
  };

  const setValueStep = (step, data) => {
    setFieldValue((value) => ({
      ...value,
      [step]: data,
    }));
  };

  return (
    <>
      <Header />
      <S.Container>
        <S.Header>
          <S.HeaderIcon>
            <FiUserPlus size={30} color="#9EB9C3" />
            <S.Title>
              Crie sua conta para <br /> prosseguir com os termos <br /> e
              Condições
            </S.Title>
          </S.HeaderIcon>
        </S.Header>

        <S.Right>
          {currentStep === STEPS.STEP_1 && (
            <Step1
              handleNext={handleNext}
              value={fieldValue.step1}
              setValueStep={(value) => setValueStep("step1", value)}
            />
          )}
          {currentStep === STEPS.STEP_2 && (
            <Step2
              handleNext={handleNext}
              value={fieldValue.step2}
              setValueStep={(value) => setValueStep("step2", value)}
            />
          )}
          {currentStep === STEPS.STEP_3 && (
            <Step3
              handleNext={handleNext}
              value={fieldValue.step3}
              setValueStep={(value) => setValueStep("step3", value)}
              loading={loading}
            />
          )}
          {currentStep === STEPS.STEP_4 && (
            <Step4
              handleNext={handleNext}
              value={{ ...fieldValue.step1, ...fieldValue.step4 }}
              setValueStep={(value) => setValueStep("step4", value)}
            />
          )}
        </S.Right>
      </S.Container>
    </>
  );
};

export default Signup;
