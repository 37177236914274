import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../../../components/Input";
import * as S from "../../styles";
import Button from "../../../../components/Button";
import { Step2Schema } from "./schema";
import { useCallback } from "react";
import { STEPS } from "../steps";

const Step2 = ({ handleNext, value, setValueStep }) => {
  const methods = useForm({
    resolver: yupResolver(Step2Schema),
    defaultValues: value,
  });

  const { register } = methods;
  const errors = methods.formState.errors;

  const submit = useCallback(
    (values) => {
      setValueStep(values);
      console.log("21", { ...value, ...values });
      handleNext(STEPS.STEP_3, { ...value, ...values });
    },
    [value]
  );
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)}>
        <S.Group>
          <S.TitleGroup>
            <b>2.</b> Dados Pessoais
          </S.TitleGroup>
          <Input
            styling="secondary"
            label="RG"
            placeholder="rg"
            {...register("rg")}
            name="rg"
            maxLength={11}
            error={errors.rg?.message}
          />

          <Input
            styling="secondary"
            label="CPF"
            placeholder="cpf"
            {...register("cpf")}
            name="cpf"
            mask="999.999.999-99"
            error={errors.cpf?.message}
          />

          <Input
            styling="secondary"
            label="Data de Nascimento"
            placeholder="Data de Nascimento"
            {...register("dateOfBirth")}
            name="dateOfBirth"
            mask="99/99/9999"
            error={errors.dateOfBirth?.message}
          />

          <S.Document {...register("gender")}>
            <option value="">Qual é seu sexo?</option>
            <option value="masculino">Masculino</option>
            <option value="feminino">Feminino</option>
          </S.Document>
          {errors.gender?.message}

          <Button type="button" onClick={() => handleNext(1)}>
            Voltar
          </Button>
          <Button>Próximo</Button>
        </S.Group>
      </form>
    </FormProvider>
  );
};

export default Step2;
