import styled, { css } from 'styled-components';
import { TextField } from '../Input/styles';

export const Container = styled.div``;

export const Choose = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
`;

export const ChooseBg = styled.div`
  background: transparent linear-gradient(180deg, #209fce 0%, #2c7bb7 100%) 0%
    0% no-repeat padding-box;
  border-radius: 16px;
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 21px;

  box-shadow: 0px 5px 15px 0px #e2eced;
  margin-bottom: 1em;
  transition: 0.3s;
`;

export const ChooseIcon = styled.img.attrs(({ icon }) => ({
  src: `/images/icon/${icon}.svg`,
}))`
  ${({ width, height }) => css`
    width: ${width};
    height: ${height};
  `}
`;

export const ChooseTitle = styled.div`
  color: #2c9eca;
  font-size: 1.6rem;
`;

export const ChooseItem = styled.div`
  ${({ active }) => css`
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    align-items: center;

    cursor: pointer;
    gap: 0.5rem;

    ${!active &&
    css`
      ${ChooseBg} {
        background: #fff;
        transition: 0.3s;
      }

      ${ChooseTitle} {
        color: #7b7b7b;
      }
    `}
  `}
`;

export const CardStatic = styled.div`
  ${({ isFlipped }) => css`
    width: 34.3rem;
    height: 19.1rem;
    margin-bottom: 4rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%) ${isFlipped ? 'rotateY(180deg)' : 'rotateY(0)'};
    background-color: transparent;
    transform-style: preserve-3d; // Preserva o estilo 3D
    transition: transform 0.6s; // Suaviza a transformação
    perspective: 1000px; // Adiciona perspectiva ao efeito
  `}
`;

export const CardStaticFront = styled.div`
  background-image: url('/images/backgrounds/card-front.svg');
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;

  backface-visibility: hidden; // Esconde a parte traseira
`;
export const CardStaticBack = styled.div`
  background-image: url('/images/backgrounds/card-back.svg');
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;

  backface-visibility: hidden; // Esconde a parte traseira
  transform: rotateY(180deg); // Rotaciona o lado de trás para que comece virado
`;

export const CardNumber = styled.div`
  color: #ffffff;
  font-size: 1.4rem;
  letter-spacing: 0.3rem;
  position: absolute;
  bottom: 5rem;
  left: 2.5rem;
`;
export const CardName = styled.div`
  color: #ffffff;
  font-size: 1.8rem;
  bottom: 2.3rem;
  left: 2.5rem;
  position: absolute;
`;
export const CardCvv = styled.div`
  color: #7b7b7b;
  font-size: 1.4rem;

  position: absolute;
  bottom: 6.5rem;
  right: 5.3rem;
  text-align: right;
`;

export const CardExpiryDate = styled.div`
  color: #fff;
  font-size: 1.4rem;

  position: absolute;
  bottom: 3.5rem;
  right: 5.3rem;
  text-align: right;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  ${TextField} {
    border-radius: 1.6rem;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export const Group = styled.div``;

export const Disclaimer = styled.div`
  text-align: center;
  color: #3080af;
  font-weight: 1.4rem;
  margin: 2rem auto;
`;
