import DatePicker, { registerLocale } from 'react-datepicker';
// import { setHours, setMinutes } from 'date-fns'
import pt from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useParams } from 'react-router-dom';

import VideoChat from '../../components/VideoChat';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { httpService } from '../../services/axios';

registerLocale('pt', pt);

let intervalStatusOnAppointment = null;

const MedicalAppointment = () => {
  const params = useParams();

  const [isFinished, setIsFinished] = useState(false);
  const user = JSON.parse(localStorage.getItem('userInfo'));
  const name = user?.name || 'Usuário';
  const appointment = localStorage.getItem('medicalAppointmentId')

  const handleDoctorEnter = async () => {
    const { data: response } = await httpService.get(
      `/medical-appointment/${appointment}/status`,
    );

    if(response.data.attributes.status === "done") {
      clearInterval(intervalStatusOnAppointment);
      setIsFinished(true);
      return;
    }
  }

  const handleIntervalStatus = () => {
    handleDoctorEnter()
    intervalStatusOnAppointment = setInterval(handleDoctorEnter, 10000);
  };

  useEffect(() => {
    Swal.close();

    handleIntervalStatus();

    return () => {
      clearInterval(intervalStatusOnAppointment);
    }
  }, [])

  return (
    <VideoChat
      name={name}
      appointment={appointment}
      roomName={params.room}
      isCustomer
      isFinished={isFinished}
    />
  );

};

export default MedicalAppointment;
