import styled from 'styled-components';

export const Container = styled.div``;
export const IconBox = styled.div`
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43px;
  height: 43px;
  padding: 1px;
  border-radius: 39px;
  background: #dfedf4;
  border: 1px solid #dfedf4;

  img {
    transition: transform 0.3s ease; // Apply the transition to the transform property of the img
  }

  &:hover {
    border: 1px solid #dfedf4;
    background: transparent;

    img {
      transform: translateX(-5px);
    }
  }
`;

export const LeftIcon = styled.img`
  width: 24px;
`;

export const Header = styled.div`
  width: 100%;
  margin: 0 auto;
  border-radius: 0px 0px 41px 41px;
  background: var(--WhiteBlue, linear-gradient(180deg, #f2f8fc 0%, #fff 100%));
  box-shadow: 0px 0px 30px 0px rgba(226, 236, 237, 0.5);
  padding: 1em 3em;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
