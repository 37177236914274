import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Alert from '../../../components/Alert';
import CallToAction from '../../../components/CallToAction';
import Header from '../../../components/Header';
import * as S from './styles';

function Template({
  title,
  description,
  alertDescription,
  isLoading,
  buttonText,
  nextPage,
  nextPageTitle,
  nextPageSize,
  items,
  type,
  isContraindication,
  rightsQuestions,
}) {
  const navigate = useNavigate();
  const navigateToNextPage = () => {
    const itemsSelected = items.filter((item) => isSelected(item.id));
    localStorage.setItem(type, JSON.stringify(itemsSelected));
    navigate(nextPage);
  };
  const [list, setList] = useState(items);
  const [alert, setAlert] = useState(false);
  const [videoEnded, setVideoEnded] = useState(true); // TODO: alterar para false

  const [videoNotEndedMessages, setVideoNotEndedMessages] = useState({});

  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setList(items);
  }, [items]);

  const isRight = (id) => {
    return rightsQuestions.includes(id);
  };

  const isSelected = (id) => {
    return selectedItems.includes(id);
  };

  const toggleAlert = () => {
    if (alert) {
      setVideoNotEndedMessages({
        title,
        description: alertDescription,
        buttonText,
      });
    }

    setAlert(!alert);
  };

  const handleSelectItem = (id) => {
    if (isContraindication && !isSelected(id)) {
      toggleAlert();
    }
    if (isSelected(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const isDisabled = () => {
    const selectedRights = selectedItems.filter((item) => isRight(item));
    const selectedWrongs = selectedItems.filter((item) => !isRight(item));

    if (selectedWrongs.length) {
      return true;
    }

    return rightsQuestions.length && selectedRights.length === 0;
  };

  const toggleWhenNotSeeVideo = () => {
    if (!videoEnded) {
      toggleAlert();
      setVideoNotEndedMessages({
        title: 'Assista o vídeo até o final',
        description: 'Para continuar, assista o vídeo até o final.',
        buttonText: 'Entendi',
      });
    }
  };

  useEffect(() => {
    if (isLoading) {
      Swal.fire({
        title: 'Estamos buscando as informações do formulário',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
    }

    if (!isLoading) {
      Swal.close();
    }
  }, [isLoading]);

  return (
    <>
      <Alert
        title={videoNotEndedMessages.title || title}
        description={videoNotEndedMessages.description || alertDescription}
        buttonText={videoNotEndedMessages.buttonText || buttonText}
        show={alert}
        toggleAlert={toggleAlert}
      />

      <Header />

      <S.Container>
        <S.Grid>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
          <S.List>
            {!videoEnded && isContraindication && (
              <S.Overlay onClick={toggleWhenNotSeeVideo} />
            )}
            {list.map((item) => (
              <S.Item
                key={`${item.id}-${type}`}
                onClick={() => handleSelectItem(item.id)}
                isRight={isRight(item.id)}
                isSelected={isSelected(item.id)}
              >
                {item.attributes.name}
              </S.Item>
            ))}
          </S.List>
        </S.Grid>

        <S.Action>
          {!videoEnded && isContraindication && (
            <S.Overlay onClick={toggleWhenNotSeeVideo} />
          )}
          <CallToAction
            onClick={navigateToNextPage}
            disabled={isDisabled()}
            size={nextPageSize}
          >
            {nextPageTitle}
          </CallToAction>
        </S.Action>
      </S.Container>
    </>
  );
}

export default Template;
