import DatePicker, { registerLocale } from "react-datepicker";
// import { setHours, setMinutes } from 'date-fns'
import pt from "date-fns/locale/pt-BR";
import { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";

import Swal from "sweetalert2";
import Button from "../../components/Button";
import Feedbacks from "../../components/Feedbacks";
import Logo from "../../components/Logo";
import VideoChat from "../../components/VideoChat";
import { httpService } from "../../services/axios";
import Loader from "./components/Loader";
import * as S from "./styles";

registerLocale("pt", pt);

let interval = null;
let intervalStatus = null;

const promiseAwait = async (timeoutAwait = 3000) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, timeoutAwait);
  });

const MedicalAppointment = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [appointment, setAppointment] = useState(
    localStorage.getItem("medicalAppointmentId")
  );
  const [positionLoader, setPositionLoader] = useState(true);
  const [position, setPosition] = useState("");
  const [isChat, setIsChat] = useState(false);
  const params = useParams();
  const [medicalEnter, setMedicalEnter] = useState(false);
  const navigate = useNavigate();

  const handleCreateSchedule = async () => {
    try {
      Swal.fire({
        title: "Aguarde",
        html: "Estamos buscando horários de consulta...",
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });

      const { data: response } = await httpService.post(
        `/medical-appointment`,
        {
          room: params.room,
          scheduleDate: new Date(),
          anamnesis: localStorage.getItem("anamnesesId"),
          status: "scheduled",
        }
      );

      const { data } = response;

      localStorage.setItem("medicalAppointmentId", response.id || data.id);
      setAppointment(response.id || data.id);
    } catch (error) {
      console.log("error", error);
    } finally {
      Swal.close();
    }
  };

  useEffect(() => {
    if (params.room && !appointment) {
      handleCreateSchedule();
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }

      if (intervalStatus) {
        clearInterval(intervalStatus);
      }
    };
  }, [appointment]);

  const [scheduled, setScheduled] = useState(false);

  const updateSchedule = useCallback(
    async (scheduleDate, status, appointment) => {
      await httpService.put(`/medical-appointment/${appointment}`, {
        ...(scheduleDate && { scheduleDate }),
        anamnesis: localStorage.getItem("anamnesesId"),
        status,
      });
    },
    []
  );

  const onSchedule = useCallback(async () => {
    try {
      Swal.fire({
        title: "Aguarde",
        html: "Estamos agendando sua consulta...",
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });

      await updateSchedule(startDate, "scheduled", appointment);

      Swal.close();
      setScheduled(true);

      await promiseAwait();

      navigate(`/profile`);
      Swal.close();
    } catch (error) {
      Swal.close();

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo deu errado, tente novamente mais tarde!",
      });
    }
  }, [appointment, startDate]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsChat(true)
  //   }, 5000)
  // }, [])

  // useEffect(() => {
  //   if (scheduled) {
  //     setTimeout(() => {
  //       navigate('/')
  //     }, 3000)
  //   }
  // }, [scheduled])

  useEffect(() => {
    if (medicalEnter) {
      handleIntervalStatus();
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }

      if (intervalStatus) {
        clearInterval(intervalStatus);
      }
    };
  }, [medicalEnter]);

  const handleGetQueuePosition = async () => {
    try {
      setPositionLoader(true);
      const { data } = await httpService.get(
        `/medical-appointment/${appointment}/queue`
      );

      setPosition(data.position);

      if (data.position === 0) {
        Swal.fire({
          title: "Oba!",
          html: "Sua vez chegou, aguarde enquanto um médico entra na sala...",
          didOpen: () => {
            Swal.showLoading();
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
        });

        setMedicalEnter(true);

        await promiseAwait(8000);
        // setIsChat(true);
        clearInterval(interval);
        // Swal.close();
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setPositionLoader(false);
    }
  };

  const handleDoctorEnter = async () => {
    const { data: response } = await httpService.get(
      `/medical-appointment/${appointment}/status`
    );

    if (response?.data?.attributes?.status === "inmeeting") {
      clearInterval(intervalStatus);

      Swal.fire({
        title: "O médico entrou na sala!",
        html: "Estamos te redirecionando ao atendimento!",
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });

      // setIsChat(true);
      clearInterval(intervalStatus);

      if (interval) {
        clearInterval(interval);
      }

      if (intervalStatus) {
        clearInterval(intervalStatus);
      }

      await promiseAwait(8000);

      navigate(`/medical-appointment/${params.room}/call`);
      Swal.close();
    }
  };

  const handleIntervalStatus = () => {
    intervalStatus = setInterval(handleDoctorEnter, 5000);
  };

  const handleInterval = () => {
    interval = setInterval(handleGetQueuePosition, 5000);
  };

  useEffect(() => {
    handleGetQueuePosition();
    if (appointment) {
      handleInterval();
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }

      if (intervalStatus) {
        clearInterval(intervalStatus);
      }
    };
  }, [appointment]);

  useEffect(() => {
    return () => {
      if (interval) {
        clearInterval(interval);
      }

      if (intervalStatus) {
        clearInterval(intervalStatus);
      }
    };
  }, []);
  if (isChat) {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const name = user?.name || "Usuário";
    return (
      <VideoChat
        name={name}
        appointment={appointment}
        roomName={params.room}
        isCustomer
        updateSchedule={updateSchedule}
      />
    );
  }

  if (scheduled) {
    return (
      <Feedbacks
        show={scheduled}
        title={() => (
          <span>
            Agendamento feito <br /> com sucesso
          </span>
        )}
        description={() =>
          `Você tem uma consulta marcada para o dia ${startDate.toLocaleDateString(
            "pt-BR"
          )} às ${startDate.toLocaleTimeString("pt-BR")}`
        }
        loading
      />
    );
  }

  return (
    <S.Container>
      <S.Top>
        <S.TopWrapper>
          <S.TopLeft>
            <Logo height="2rem" width="fit-content" />
            <S.Description>
              Você está na fila de espera <br />
              Seu médico estará disponível em breve <br /> <br />
            </S.Description>
            <S.Description>
              O tempo médio de espera é de: 5 minutos
            </S.Description>
            <S.DisclaimerPosition>
              <S.Description>
                A sua posição é:{" "}
                {!positionLoader && position >= 0 && (
                  <strong>{position}</strong>
                )}
              </S.Description>
              {positionLoader && <Loader />}
            </S.DisclaimerPosition>
          </S.TopLeft>
          <S.TopRight>
            <S.ClockIcon />
          </S.TopRight>
        </S.TopWrapper>

        <S.ProgressBar>
          <S.ProgressBarValue />
        </S.ProgressBar>

        <S.HeadingDescription>
          Enquanto aguarda, você pode conferir <br /> alguma de nossas pilulas
          conscientizadoras <br /> desse universo canábico.
        </S.HeadingDescription>

        {/* <S.CarouselWrapper>
          <S.Carousel>
            <S.CarouselItem>
              <S.CarouselItemTitle>Benefícios da Cannabis</S.CarouselItemTitle>
            </S.CarouselItem>

            <S.CarouselItem>
              <S.CarouselItemTitle>Benefícios da Cannabis</S.CarouselItemTitle>
            </S.CarouselItem>

            <S.CarouselItem>
              <S.CarouselItemTitle>Benefícios da Cannabis</S.CarouselItemTitle>
            </S.CarouselItem>

            <S.CarouselItem>
              <S.CarouselItemTitle>Benefícios da Cannabis</S.CarouselItemTitle>
            </S.CarouselItem>
          </S.Carousel>
        </S.CarouselWrapper> */}
      </S.Top>

      <S.Bottom>
        <S.Disclaimer>
          <b>
            {" "}
            Ou você pode também agenda sua consulta! <br /> É só escolher uma
          </b>
          data, confirmar,
          <br /> e nós lembraremos você!
        </S.Disclaimer>

        <DatePicker
          selected={startDate}
          locale="pt"
          onChange={(date) => setStartDate(date)}
          showTimeSelect
          minDate={new Date()}
          dateFormat="d MMMM yyyy - H:mm"
        />
        {/* marcar um horario personalizado */}

        <S.Actions>
          <Button size="small" onClick={() => setStartDate(new Date())}>
            Limpar
          </Button>

          <Button size="small" onClick={onSchedule}>
            Marcar
          </Button>
        </S.Actions>
      </S.Bottom>
    </S.Container>
  );
};

export default MedicalAppointment;
