import { useEffect, useState } from "react";
import AttachDocuments from "../../../components/AttachDocuments";
import Header from "../../../components/Header";
import * as S from "./styles";
import { httpService } from "../../../services/axios";

const Documents = () => {
  const [list, setList] = useState([]);

  const handleGetDocuments = async () => {
    const { data } = await httpService.get("/medical-appointment/documents");
    console.log("data::10", data);

    if (!data?.length) return;

    const listInfo = data.map((item) => ({
      title: item.attributes.title,
      id: item.id,
    }));

    setList(listInfo);
  };
  useEffect(() => {
    handleGetDocuments();
  }, []);

  return (
    <>
      <Header />
      <S.Container>
        <S.Title>
          <S.Icon src="/images/icon/account/files-grey.svg" /> Seus documentos
        </S.Title>

        {list?.map((item) => (
          <AttachDocuments
            key={`attach-${item.id}`}
            title={item.title}
            id={item.id}
          />
        ))}
      </S.Container>
    </>
  );
};

export default Documents;
