import Header from '../../../components/Header';
import MacroAppointments from '../../../components/MacroAppointments';
import * as S from './styles';

const Orders = () => {
  return (
    <>
      <Header />
      <S.Container>
        <S.Title>
          <S.Icon src='/images/icon/account/appointments-grey.svg' /> Meus
          Agendamentos
        </S.Title>

        <MacroAppointments />
      </S.Container>
    </>
  );
};

export default Orders;
