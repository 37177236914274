import { useCallback, useEffect, useState } from 'react'
import { httpService } from '../../../services/axios'
import Template from '../Template'
const Screening = () => {
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  
  const handleGetListItems = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await httpService.get('/anamneses/flow/screenings')
      setItems(data.data)
    } catch (error) {
      console.log("error", error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    handleGetListItems()
  }, [handleGetListItems])

  const rightsQuestions = () => items?.map(item => item.id)

  return (
    <Template
      isLoading={isLoading}
      items={items}
      rightsQuestions={rightsQuestions()}
      presentation='screening'
      type='screening'
      title='Triagem'
      description='Você sente ou foi diagnosticado com algum dos sintomas abaixo?'
      nextPageTitle='Termos e Aceite'
      nextPageSize='medium'
      nextPage='/terms-and-accept'
    />
  )
}

export default Screening
