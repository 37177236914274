import Title from '../Title'
import * as S from './styles'

const Alert = ({ title, description, buttonText, icon, show, toggleAlert }) => {
  return (
    <S.Container show={show}>
      <S.Content>
        <S.Icon />
        <Title type='black'>{title}</Title>
        <S.Description>{description}</S.Description>
      </S.Content>

      <S.Button onClick={toggleAlert}>
        {buttonText}
        {icon && <S.ButtonIcon />}
      </S.Button>

    </S.Container>
  )
}

export default Alert
