import Logo from "../../components/Logo";
import * as S from "./styles";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import { httpService } from "../../services/axios";
import Swal from "sweetalert2";
import Input from "../../components/Input";
import { FormProvider, useForm } from "react-hook-form";
import { ObjectSchema, ref, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

function RecoverPassword() {
  const [value, setValue] = useState({
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);

  const redirectToHome = () => {
    window.location.href = "/";
  };

  const Schema = new ObjectSchema({
    password: string()
      .min(5, "Humm, a senha está muito curta...")
      .max(30, "Senha muito longa...")
      .required("Oops, parece que você não adicionou a sua senha..."),
    confirmPassword: string()
      .oneOf([ref("password"), null], "As senhas não coincidem")
      .required("Você precisa confirmar a senha"),
  });

  const methods = useForm({
    resolver: yupResolver(Schema),
  });

  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      const params = new URLSearchParams(window.location.search);
      const QueryParam = params.get("code");

      const response = await httpService.put(`/auth/password`, {
        ...value,
        code: QueryParam,
      });
      Swal.fire({
        title: "Sucesso",
        text: "Senha alterada",
        icon: "success",
      });
    } catch (error) {
      if (error.response.status === 400) {
        Swal.fire({
          title: "Token expirado",
          text: "Recupere a senha novamente",
          icon: "error",
        });
      }
    } finally {
      setLoading(false);

      setTimeout(redirectToHome, 2000);
    }
  };

  const errors = methods.formState.errors;

  useEffect(() => {
    if (loading === true) {
      Swal.fire({
        title: "Carregando",
        text: "Mudando a senha",
      });
    }
  }, [loading]);

  return (
    <S.Container>
      <S.Top>
        <Logo type="bluepharm" height="3rem" />
        <S.Title>Nova senha</S.Title>
      </S.Top>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <S.Wrapper>
            <Input
              styling="secondary"
              label="Senha"
              placeholder="senha"
              type="password"
              name="password"
              error={errors.password?.message}
              onChange={(e) =>
                setValue({
                  ...value,
                  password: e.target.value,
                })
              }
            />
            <Input
              styling="secondary"
              label="Confirmar senha"
              placeholder="Confirmar senha"
              type="password"
              name="confirmPassword"
              onChange={(e) =>
                setValue({ ...value, confirmPassword: e.target.value })
              }
              error={errors.confirmPassword?.message}
            />
            <Button
              variant="outline"
              icon="right"
              iconColor="blue"
              disabled={loading}
            >
              Mudar a senha
            </Button>
          </S.Wrapper>
        </form>
      </FormProvider>
    </S.Container>
  );
}

export default RecoverPassword;
