import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Header from '../../components/Header/';
import * as S from './styles';
import { PROVIDER_NAME, TOKEN_NAME } from '../../env';
const SettingsIcon = '/images/icon/account/settings.svg';
const DocumentsIcon = '/images/icon/account/files.svg';
const AppointmentsIcon = '/images/icon/account/appointments.svg';
const FaqIcon = '/images/icon/account/faq.svg';

const Profile = () => {
  const navigate = useNavigate();

  const handleSettingsClick = () => {
    navigate('/profile/settings');
  };

  const handleDocumentsClick = () => {
    navigate('/profile/documents');
  };

  const handleAppointmentsClick = () => {
    navigate('/profile/orders');
  };

  const handleFaqClick = () => {
    navigate('/profile/faq');
  };

  const handleLogoutClick = () => {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(PROVIDER_NAME);
    navigate('/');
  }

  return (
    <>
      <Header />
      <S.Container>
        <S.Title>
          <S.Icon src='/images/icon/account/user-menu.svg' /> Menu de Usuário
        </S.Title>

        <S.Menu>
          <Button
            variant='grey'
            block
            iconColor='white'
            iconSrc={SettingsIcon}
            onClick={handleSettingsClick}
          >
            Meu Perfil
          </Button>

          <Button
            variant='grey'
            block
            iconColor='white'
            iconSrc={DocumentsIcon}
            onClick={handleDocumentsClick}
          >
            Meus Documentos
          </Button>

          {/* <Button
            variant='grey'
            block
            iconColor='white'
            iconSrc={AppointmentsIcon}
            onClick={handleAppointmentsClick}
          >
            Consultas Agendadas
          </Button> */}

          <Button
            variant='grey'
            block
            iconColor='white'
            iconSrc={FaqIcon}
            onClick={handleFaqClick}
          >
            Dúvidas frequentes
          </Button>

          <Button
            variant='small'
            size="small"
            block
            iconColor='white'
            onClick={handleLogoutClick}
          >
            Sair
          </Button>
        </S.Menu>
      </S.Container>
    </>
  );
};

export default Profile;
