// styles.ts
import styled from "styled-components";

export const DocumentCard = styled.div`
  border-radius: 21px;
  border: 1.5px solid var(--BgBlur, rgba(255, 255, 255, 0.3));
  background: var(
    --WhiteBluInverter,
    linear-gradient(180deg, #fff 0%, #f4f9ff 100%)
  );
  box-shadow: 0px 9.344px 35.039px 0px rgba(147, 174, 186, 0.2);
  transition: 0.3s;
  width: 100%;
  border-radius: 10px; // Your border-radius may vary
  padding: 20px;

  cursor: pointer;
  position: relative; // For absolute positioning of the icons
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const DocumentHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const DocumentTitle = styled.h2`
  color: #333;
  margin: 0;
  font-size: 18px;
  display: flex;
  color: #333;
  gap: 0.5em;
`;

export const IconsContainer = styled.div`
  // Adjust the styles as needed for the icons
`;

export const DocumentContent = styled.div`
  font-size: 16px;
  color: #666;
`;

// Add specific styles for your icons, buttons, etc.

export const Icon = styled.img`
  height: 22px;
`;

export const DocumentThumbnail = styled.img`
  width: 100%; // or max-width if you want to restrict the size
  border-radius: 5px; // optional, for styled corners
  margin-top: 10px; // space between the title and the image
  // Add any other styles you want for the image
`;
